<template>
  <div class="pa-0 ma-0 d-flex flex-column">
    <jumbotron
      :src="vendorInfo.bannerUrl"
      :aspect-ratio="3.5"
      color="primary"
    >
      <v-container fluid class="fill-height white--text ma-0 pa-1">
        <v-row class="fill-height ma-0" align="end">
          <v-col>
            <h1
              :class="{
                'display-3': isOnPC,
                'display-1': !isOnPC
              }"
              style="text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75)"
              v-text="vendorInfo.name"
            />
            <!-- <vendor-contacts
              :contacts="vendorInfo.contacts"
            /> -->
          </v-col>
        </v-row>
      </v-container>
    </jumbotron>
    <v-container>
      <h1 class="title">Contact Info</h1>
      <vendor-contacts :contacts="vendorInfo.contacts" />
    </v-container>
    <v-container>
      <h2 class="headline">Products</h2>
      <product-list :vendor-id='vendorId'/>
    </v-container>
  </div>
</template>

<script>
import firebase from '../plugins/firebase'

import Jumbotron from '../components/Jumbotron'
import VendorContacts from '../components/VendorDetailsView/VendorContactsv2'
import ProductList from '../components/VendorDetailsView/ProductList'

export default {
  name: 'VendorView',

  components: {
    jumbotron: Jumbotron,
    'vendor-contacts': VendorContacts,
    'product-list': ProductList
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  data () {
    return ({
      loading: false,
      vendorInfo: {}
    })
  },

  props: {
    vendorId: {
      type: String,
      required: true
    }
  },

  async created () {
    this.$data.loading = true
    this.getVendorData()
      .then(vendorData => { this.$data.vendorInfo = Object.assign({}, vendorData) })
      .catch(e => {
        // console.error(e)
        this.$store.commit('toast/setError', 'Cannot fetch vendor data.')
      })

    this.$data.loading = false
  },

  methods: {
    getVendorData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/vendors/${this.vendorId}`).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    }
  },

  watch: {
    vendorInfo: function (v) {
      document.title = `${v.name} - AmborJo Marketplace`
    }
  }
}
</script>
