<template>
  <v-card :to="`products/${product.id}`">
    <v-img
      aspect-ratio="0.75"
      :src="product.mainPhoto"
      class="fill-height align-end white--text no-underline text-no-wrap"
      gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
      </template>
      <v-card-title class="white--text no-underline text-no-wrap" v-text="product.name" />
      <v-card-subtitle class="white--text no-underline" v-text="formattedPrice" />
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: 'ProductCard',

  props: {
    product: {
      require: true,
      type: Object
    }
  },

  computed: {
    formattedPrice () {
      return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(this.product.price)
    }
  }
}
</script>
