<template>
  <v-card>
    <v-list dense>
      <v-list-item two-line
        v-for="(value, k) in contacts" :key="`${k}${value}`"
      >
        <v-list-item-icon>
          <v-icon :title="k" v-if="k=='Instagram'">mdi-instagram</v-icon>
          <v-icon :title="k" v-else-if="k=='WhatsApp'">mdi-whatsapp</v-icon>
          <v-icon :title="k" v-else-if="k=='Facebook'">mdi-facebook</v-icon>
          <v-icon :title="k" v-else-if="k=='Telephone'">mdi-telephone</v-icon>
          <v-icon :title="k" v-else-if="k=='E-mail'">mdi-email</v-icon>
          <v-icon :title="k" v-else>mdi-message-text</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="k" />
          <v-list-item-subtitle v-text="value" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    contacts: {
      type: Object,
      required: true
    }
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>
