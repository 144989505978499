<template>
  <v-container class="pa-0 ma-0">
    <v-row v-if="loading" align="center" justify="center">
      <v-col><v-progress-circular indeterminate color="primary" /></v-col>
    </v-row>
    <v-row v-else-if="products.length">
      <v-col xs="12" sm="12" md="4" lg="3"
        v-for="product in products"
        :key="JSON.stringify(product)"
      >
        <product-card :product="product" />
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-col><p class="text-center font-weight-thin">No product to display.</p></v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '../../plugins/firebase'
import ProductCard from './ProductCard'

export default {
  components: {
    'product-card': ProductCard
  },

  props: {
    'vendor-id': {
      required: true,
      type: String
    }
  },

  data () {
    return ({
      loading: false,
      products: []
    })
  },

  methods: {
    getProducts () {
      return new Promise((resolve, reject) => {
        var resolveValue = []
        firebase.firestore().collection(`/vendors/${this.vendorId}/products/`).get()
          .then(collectionSnapshot => {
            collectionSnapshot.docs.forEach(doc => {
              resolveValue.push(Object.assign({ id: doc.id }, doc.data()))
            })
            resolve(resolveValue)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async created () {
    this.$data.loading = true
    try {
      this.$data.products = await this.getProducts()
    } catch (e) {
      this.$store.commit('toast/setError', 'Cannot get product list.')
      // console.error(e)
    }
    this.$data.loading = false
  }
}
</script>
